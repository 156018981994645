import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"

// import required modules
import { Navigation } from "swiper"

import { Container } from "./../../../styles/global"
import { Wrapper, Title, Desc } from "./../../../styles/Jinolo.style"

const Jinolo = () => {
  return (
    <Wrapper>
      <Container>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          {Slides.map(({ id, title, desc }) => {
            return (
              <SwiperSlide key={id}>
                <Title>{title}</Title>
                <Desc>{desc}</Desc>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    </Wrapper>
  )
}

export default Jinolo

const Slides = [
  {
    id: 1,
    title: `“Jinolo’s viewer helps with collaboration between the engineering, design, marketing and sales.” `,
    desc: "- Industrial Designer, Industrial Design Company in California, USA",
  },
  {
    id: 2,
    title: `“Jinolo is useful for sending files for manufacture to make sure there are no liberties taken in the process” `,
    desc: "- Head of Engineering, Parts Design Company in Sydney, Australia",
  },

  {
    id: 3,
    title: `“Jinolo is really fast! That’s a big benefit when loading large 3D models.” `,
    desc: "- Mechanical Design Consultant in Sydney, Australia",
  },
]
