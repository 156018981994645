import React, {useRef, useState} from "react"
import styled from "styled-components"
import {graphql, useStaticQuery, Link} from "gatsby"
import Img from "gatsby-image"
import headerimg from "./../../../images/home/image 2.png"

import {Container} from "./../../../styles/global"

import {
    SinupButton
} from "../../common/navigation/navigation.style"
import SignUpButton from "../../common/signUpButton";

const Header = () => {
    const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "hero" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    const textOptions = {
        option1: {
            title: "View anywhere. Collaborate in 3D.",
            subtitle: "Jinolo is a collaborative 3D platform that helps design teams communicate on CAD in real-time."
        },
        option2: {
            title: "Upload Once. View Anywhere.",
            subtitle: "With our platform, design, discuss and decide in real-time with your team, wherever they are."
        },
        option3: {
            title: "Embrace the Future with Our Platform",
            subtitle: "Revamp your design process, reduce confusion and bring your concepts to life faster."
        },
        option4: {
            title: "3D Review: A Revolution in Design",
            subtitle: "Transcend traditional boundaries. Discuss and iterate directly on 3D models."
        },
        option5: {
            title: "Experience the Power of Real-Time 3D Review",
            subtitle: "Review designs effortlessly. All you need is a browser."
        }
    };

// to use, select the desired option
    const selectedOption = textOptions.option1;

    const inputRef = useRef();
    const [email, setEmail] = useState('');
    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };
    return (
        <HeaderWrapper id="top">
            <Container>
                <Flex>
                    {/*<HeaderTextGroup>*/}
                    <div className="md:pb-10 pr-7">
                        {/* <Subtitle>Personal Finance</Subtitle> */}
                        <div className="pb-10">
                            <div className="font-grotesk-bold text-white mx-auto my-0 mb-10 text-5xl">
                                {selectedOption.title}
                            </div>
                            <div className="font-grotesk-medium text-primary mx-auto my-0 text-2xl">
                                {selectedOption.subtitle}
                            </div>
                        </div>


                        {/* <h3>Find out more</h3> */}

                        {/*  <HeaderButton>Sign Up</HeaderButton>*/}
                        {/*  /!*<HeaderButton>Book Onboarding</HeaderButton>*!/*/}
                        {/*</HeaderForm>*/}
                        <div className="text-navy pb-10 ">
                            <input
                                type="text"
                                placeholder="Enter your work email"
                                value={email}
                                onChange={handleInputChange}
                                className="font-grotesk-semi-bold py-2 w-full border-2 text-xl border-primary focus:outline-none focus:border-primary px-5 rounded-full mr-4 h-11 align-self-end font-grotesk-medium placeholder-navy"
                            />


                            <SignUpButton email={email} className="w-full"/>

                            <div className="text-white px-2 py-1 font-grotesk-light">No credit card required.</div>
                        </div>


                    </div>
                    <div className="lg:pl-5 pb-5">
                        <video autoPlay loop muted playsInline className="autoplay-video rounded-xl object-cover">
                            <source
                                src="https://storage.googleapis.com/jinolo-landing-assets/Demo_Landing.mp4"
                                type="video/mp4"/>
                        </video>
                    </div>
                    {/*</ImageWrapper>*/}
                </Flex>
            </Container>
        </HeaderWrapper>
    )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.background.navy};
  padding: 160px 0 10px 0;
  position: relative;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 2fr 3fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    //grid-gap: 64px;
    padding-bottom: 17px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  ${(props) => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  color: ${(props) => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.color.secondary};
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 23px;
  padding: 8px 16px;
  outline: 0px;

  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 50px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 23px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  margin-top: -20%;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
    margin-top: 3em;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
