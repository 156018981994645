import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Header from "./../components/views/Home/header"
import Benefits from "./../components/views/Home/benefits"
import SimplestWay from "./../components/views/Home/SimplestWay"
import EasySharing from "../components/views/Home/EasySharing"
import Jinolo from "../components/views/Home/Jinolo"
import SupportedBy from "../components/views/Home/SupportedBy"
import ReduceCosts from "../components/views/Home/ReduceCosts"
import { Script } from "gatsby"
import Hotjar from '@hotjar/browser';
import { GatsbySeo } from "gatsby-plugin-next-seo"
import {graphql, useStaticQuery} from "gatsby";
import {Helmet} from "react-helmet";

const IndexPage = ({location}) => {
    const siteId = 3367460;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
    return (
        <Layout location={location}>
            <SEO
                title="Free CAD Viewer: 3D File Sharing for Engineering and Industrial Design"
                description="Jinolo streamlines manufacturing projects by connecting designers and engineers to help share and collaborate on 3D and 2D CAD. Sign up free."
                // image={ogImg}
            />

            <Header/>

            {/*<SimplestWay/>*/}
            <SupportedBy/>
            <EasySharing/>
            <Jinolo/>
            <Benefits/>
            <ReduceCosts/>


            {/*<script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/22162875.js"/>*/}

            {/*<Helmet>*/}

            <Script id="hs-script-loader" src="https://js-na1.hs-scripts.com/22162875.js"/>

            {/*</Helmet>*/}
        </Layout>

    )
}

export default IndexPage
