import React from "react"
import styled from "styled-components"
import RightImg from "./../../../images/home/Homepage-Graphic1.png"
import LeftImg from "./../../../images/home/Homepage-Graphic3.png"
import powerView from "../../../images/home/Homepage-Graphic2.png"
import { Container } from "../../../styles/global"
import {Link} from "gatsby";

const EasySharing = () => {
  return (
    <Container>
      <Wrapper>

        <Row>
          <ImageWrapperLeft>
            <Image src={powerView} alt="Jinolo 3d viewer in browser" />
          </ImageWrapperLeft>
          <Col>
            <BlockTitle>CAD Anywhere, Anytime</BlockTitle>
            <Paragraph>
              Experience hassle-free viewing of 3D and 2D CAD designs on any device
              directly in your browser, no additional software required.
            </Paragraph>
          </Col>
        </Row>
        <Flex>
          <Col>
            <BlockTitle>Seamless 3D Collaboration</BlockTitle>
            <Paragraph>
              Experience the power of simultaneous design review and immediate 3D commenting,
              that boosts productivity and reduces miscommunications.
            </Paragraph>
          </Col>
          <ImageWrapper>
            <Image src={LeftImg} alt="" />
          </ImageWrapper>
        </Flex>
        <Row>
          <ImageWrapperLeft>
            <Image src={RightImg} alt="Jinolo sharing design pop up graphic" />
          </ImageWrapperLeft>
          <Col>
            <BlockTitle>Effortless Sharing</BlockTitle>
            <Paragraph>
              With just a few clicks, you can share your design via a link or an email invite,
              ensuring clients, reviewers, and suppliers are all on the same page.
            </Paragraph>
          </Col>
        </Row>
        <Button>
          <Link to="/product">Explore Product</Link>
        </Button>
      </Wrapper>
    </Container>
  )
}

export default EasySharing

// export const ButtonLink = styled.li`
//   a {
//     text-decoration: none;
//   }
//
// `

const Wrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 16px;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-gap: 64px;
    justify-content: center;
    flex-direction: column-reverse;
  }
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`
const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const BlockTitle = styled.h3`
  color: ${(props) => props.theme.color.background.navy};
  margin-bottom: 40px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    text-align: center;
    margin-bottom: 20px;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 50px;
  }
`
const ImageWrapperLeft = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 50px;
    width: 100%;
  }
`
const Image = styled.img`
  width: 100%;
`
const Paragraph = styled.h2`
  font-size: 22px;
  color: #121640;
  margin-bottom: 40px;
  line-height: 30px;
  font-weight: 100;
  max-width: 359px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    max-width: 700px;
    margin: auto;
    text-align: center;
  }
`

const Button = styled.button`
  color: white;
  text-transform: uppercase;
  background-color: #121640;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  margin: 62px auto;
  padding: 14px 40px;
  font-weight: 600;
  font-size: 14px;
  a {
    text-decoration: none;
    color: white;
  }
`
