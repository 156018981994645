import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faMoneyBillWave, faUserGroup
} from "@fortawesome/free-solid-svg-icons"

import { Section, Container } from "../../../styles/global"

const Benefits = () => (
  <Section id="benefits">
    <StyledContainer>
      <Subtitle>Jinolo helps engineering design teams</Subtitle>
      <FeaturesGrid>
        <FeatureItem>
          <FontAwesomeIcon icon={faClock} size="5x" />
          <FeatureTitle>Save Time</FeatureTitle>
          <FeatureText>
            Shorten project time by reducing the amount of back and forth revisions.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FontAwesomeIcon icon={faMoneyBillWave} size="5x" />
          <FeatureTitle>Cut Costs</FeatureTitle>
          <FeatureText>
            Reduce costs by preventing expensive manufacturing mistakes.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FontAwesomeIcon icon={faUserGroup} size="5x" />
          <FeatureTitle>Simplify Teamwork</FeatureTitle>
          <FeatureText>
            Keep key stakeholders engaged and involved in the design process.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Benefits

const StyledContainer = styled(Container)``

const Subtitle = styled.h5`
  font-size: 17px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 30px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.background.navy};
  align-self: top;
  text-align: center;
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 30px;
`

const FeatureText = styled.p`
  color: ${(props) => props.theme.color.background.navy};
  text-align: justify;
  text-align: center;
  height: 6em;
`
