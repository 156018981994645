import React from 'react';

function SignUpButton({className, email = null, action = "signup", buttonText="Sign up free"}) {
    const handleButtonClick = () => {
        const emailString = email ? encodeURIComponent(email) : '';
        if (email !== null) {
            window.location.href = `https://share.jinolo.com/?action=${action}&email=${emailString}`;
        } else {
            window.location.href = `https://share.jinolo.com/?action=${action}`;
        }
        window.gtag("event", "signup_click", {event_category: "Button", event_label: "SignUp"});


    }
    return (

        <button
            onClick={handleButtonClick}
            className={`border-2 border-primary font-grotesk-semi-bold py-2 bg-primary h-11 
                text-white uppercase rounded-full mt-4 hover:bg-white hover:text-primary 
                text-xs sm:text-sm md:text-base lg:text-lg ${className}`}>
            {buttonText}
        </button>

    );
}

export default SignUpButton;