import React from "react"
import styled from "styled-components"
import {Container} from "./../../../styles/global"
import SignUpButton from "../../common/signUpButton";

const ctaOptions = {
    option1: {
        title: "Start Your Design Revolution Now",
        subtitle: "Sign up to our free forever plan. Upgrade anytime."
    },
    option2: {
        title: "Simplify Your 3D Review Process",
        subtitle: "Get started for free today, and make design mistakes a thing of the past."
    },
    option3: {
        title: "Empower Your Team with Real-Time Reviews",
        subtitle: "Sign up now at no cost and transform your approach to 3D collaboration."
    },
    option4: {
        title: "Kickstart Your Design Innovation",
        subtitle: "Register for free and discover how easy 3D model reviewing can be."
    },
    option5: {
        title: "Join the 3D Collaboration Revolution",
        subtitle: "Try our tool for free today and streamline your design process."
    },
    option6: {
        title: "Discover Seamless 3D Reviewing",
        subtitle: "Start your free trial today. No strings attached, just better collaboration."
    },
    option7: {
        title: "Transform Your 3D Review Workflow",
        subtitle: "Get started for free and experience design collaboration like never before."
    },
    option8: {
        title: "Enhance Your Design Discussions",
        subtitle: "Sign up for free and explore new ways to collaborate on your 3D models."
    },
    option9: {
        title: "Step Into the Future of 3D Review",
        subtitle: "Register now for free and bring your design collaboration to the next level."
    },
    option10: {
        title: "Elevate Your 3D Collaboration",
        subtitle: "Start for free today and discover the future of 3D model reviewing."
    }
};

const selectedOption = ctaOptions.option1;

const ReduceCosts = () => {
    return (
        <Wrapper>
            <Container>

                <Title>{selectedOption.title}</Title>
                <Desc>{selectedOption.subtitle}</Desc>

                <div className="flex justify-center">
                    <SignUpButton className=" w-1/3"/>
                </div>

            </Container>
        </Wrapper>
    )
}

export default ReduceCosts
const HeaderTextGroup = styled.div`

  display: flex;
  justify-content: center;
  margin: 62px auto;
  padding: 12px 36px;

  margin-left: auto;
  margin-right: auto;

`

const Wrapper = styled.div`
  padding-top: 134px;
  padding-bottom: 136px;
  background-color: #121640;
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 38px;
  margin-top: 0;
  text-align: center;
  line-height: 50px;
  max-width: 846px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 36px;
  }
`
const Desc = styled.h1`
  font-size: 20px;
  color: #e43397;
  margin-bottom: 41px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
`
