import React from "react"
import styled from "styled-components"
import Img1 from "./../../../images/home/4 1.png"
import Img2 from "./../../../images/home/5 1.png"
import Img3 from "./../../../images/home/image 6.png"
import Img4 from "./../../../images/home/2.png"
import Img5 from "./../../../images/home/3.png"
import { Container } from "./../../../styles/global"

const SupportedBy = () => {
  return (
    <Wrapper>
      <Container>
        <Title>As seen in</Title>
        <Flex>
          {Slides.map(({ id, img }) => {
            return (
              <Col key={id}>
                <img src={img} className="w-52" alt="" />
              </Col>
            )
          })}
        </Flex>
      </Container>
    </Wrapper>
  )
}

export default SupportedBy

const Wrapper = styled.div`
  //margin-top: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #e3e3e3;
`

const Title = styled.h1`
  font-size: 35px;
  font-weight: 400;
  color: #121640;
  margin-bottom: 38px;
  margin-top: 0;
  text-align: center;
  line-height: 50px;
  max-width: 846px;
  margin-left: auto;
  margin-right: auto;
`
const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    flex-direction: column;
    align-items: center;
  }
`
const Col = styled.div`
  margin-bottom: 16px;
`

const Slides = [
  {
    id: 1,
    img: Img1,
  },
  {
    id: 2,
    img: Img2,
  },

  {
    id: 3,
    img: Img3,
  },
  {
    id: 4,
    img: Img4,
  },
  {
    id: 5,
    img: Img5,
  },
]
