import styled from "styled-components"
export const Wrapper = styled.div`
    padding-top: 168px;
    padding-bottom: 116px;
    background-color: #00C4CC;
`;

export const Title = styled.h1 `
    font-size: 40px;
    font-weight: 400;
    color:#121640;
    margin-bottom: 17px;
    margin-top: 0;
    text-align: center;
    line-height: 50px;
    max-width: 846px;
    margin-left: auto;
    margin-right: auto;
`;
export const Desc = styled.h2 `
    font-size: 17px;
    color:#121640; 
    line-height: 22px;
    font-weight: 200;
    max-width: 284px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`;
