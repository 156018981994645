import React from "react"
import videoImg from "./../../../images/home/image 1.png"
import styled from "styled-components"
import { Container } from "./../../../styles/global"

const SimplestWay = () => {
  return (
    <Container>
      <WrapperTitle>
        {/*<SectionTitle>The simplest way to share your 3D designs.</SectionTitle>*/}
        {/*<SectionTitle>The simplest way to share your 3D designs.</SectionTitle>*/}
          <div className="flex font-grotesk-bold text-navy justify-center mx-auto my-0 mb-10 text-center max-w-xl text-3xl">
              Share easily. View anywhere. Add 3D comments in real-time.
          </div>
        <ImgWraper>
          <Iframe
            className="responsive-iframe"
            width="1000"
            height="500"
            src={`https://www.youtube.com/embed/OlO-G_eVhyM?rel=0&autoplay=1&showinfo=0&loop=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Jinolo Demo"
          />
        </ImgWraper>
      </WrapperTitle>
    </Container>
  )
}

export default SimplestWay
const Iframe = styled.iframe`
  border-radius: 20px;
`
const WrapperTitle = styled.div`
  padding-top: 99px;
`

const ImgWraper = styled.div`
  display: flex;
  justify-content: center;
`
